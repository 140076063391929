import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('customer', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    saleReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saleReport', data)
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    inventoryStockReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventoryChartStockReport', data)
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomerDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customerDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCategoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listCategory')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAdjustmentItems(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listAdjustmentItem', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getItemDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listItem')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryDropAll')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('inventoryReportData', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    businessOverviewSale(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('businessOverviewSale', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    businessOverviewInventory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('businessOverviewInventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    
    financialPosition(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('financialPosition', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    

    fetchExpense() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listExpense')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchAdjustment() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listAdjustment')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchExpenseById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showExpense/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deletePurchaseFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deletePurchaseFile/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    filterPeriod(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('filterInventoryPeriod', queryParams)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    filterPeriodInventory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('filterInventoryStockReport', queryParams)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    pdfdownloadPurchaseOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadPurchaseOrder', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfdownloadSaleOrder(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadSaleOrder', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfdownloadAdjustment(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadAdjustment', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchIncome() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listIncome')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('accountingUsers')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    journalReport() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('generalJournal')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventoryReport', data, { responseType: 'blob' })
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfDownloadInventory(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventoryStockReport', data, { responseType: 'blob' })
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    profitLossPdf(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('profitLossDownload', data, { responseType: 'blob' })
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    compIncomeDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('compIncomeDownload', data, { responseType: 'blob' })
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    financialPositionDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('financialPositionDownload', data, { responseType: 'blob' })
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    

    ledgerPdf(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('ledgerDownload', data, { responseType: 'blob' })
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchIncomeById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showIncome/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchAdjustmentById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showAdjustment/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomerById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customer/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    savePurchaseOrder(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveExpense', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    saveSaleOrder(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveIncome', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveAdjustment(ctx, invoiceData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveAdjustment', invoiceData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('customer', customerData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    savePayment(ctx, paymentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('savePayment', paymentData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveItem', { productName: itemData.val, categoryId: itemData.categoryId })
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveAdjustmentItem(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveAdjustmentItem', { productName: itemData.val, type: itemData.type })
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveCategory(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveCategory', { categoryName: itemData })
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`customer/${customerData.id}`, customerData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`expenseStatus`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    incomeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`incomeStatus`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    adjustmentStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`adjustmentStatus`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateExpense(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateExpense`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateAdjustment(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateAdjustment`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    updateIncome(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateIncome`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchInventoryReportColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryreport_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchSaleOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('saleorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customer_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    saveAccount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveAccount', queryParams  )
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    listAccount() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listAccount')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    saveJournal(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveJournal', queryParams  )
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },


    listJournal(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('listJournal', data)
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchJournalById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showJournal/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateJournal(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateJournal/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchAccountById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showAccount/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    
    updateAccount(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateAccount/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },





  

   


  },
};
