<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->

            <b-col lg="4" md="4" sm="10" class="text-center">
              <flat-pickr placeholder="Date Range" v-model="rangeDate" onClose="testClose" class="form-control" :config="config" />
            </b-col>

            <b-col lg="3" md="3" sm="2" class="d-flex align-items-left justify-content-start mb-1 mb-md-0">
              <b-button @click="clearDate()" variant="success">Clear</b-button>
            </b-col>

            <!-- Search -->
            <b-col lg="5" md="5" sm="12" class="d-flex align-items-right justify-content-end mb-1 mb-md-0">
              <b-button variant="primary" @click="generateReport" v-if="$Can('inventory_create')">
                <span class="text-nowrap">Generate Report</span>
              </b-button>
            </b-col>
          </b-row>
        </div>

        <b-card-body body-border-variant="secondary">
          <table style="border: none; text-align: left; margin: 0%; width: 100%">
            <tr style="border: none">
              <td style="width: 50%; border: none"></td>
            </tr>
          </table>

          <h2 class="text-left">123123 B.C. LTD. </h2>
          <h6 class="text-left">Condensed Consolidated Interim Statements of Comprehensive Income </h6>

          <h6 class="text-left">(Expressed in Canadian Dollars – Unaudited) </h6>

          <p class="text-center" v-if="filterDateStart != null">Records from {{ filterDateStart }} to {{ filterDateEnd }}</p>
          <p class="text-center" v-else>All Records</p>
          <hr style="margin-top: 2em" />

            <table style="padding: 10%; width: 100%;">
      <thead>
        <tr>
          <th>Category</th>
          <th>ACC.NO</th>
          <th>Account</th>
          <th>Sub-Account</th>
          <th>Detail</th>
          <th class="text-right">Balance</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="category in categoriez">
          <tr>
            <td >&nbsp</td>
            <td></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>

          </tr>
          <tr :key="category.name" class="bg-light-secondary" style="border-bottom:1px solid black; border-collapse:collapse;">
            <td style="font-weight: bold;">{{ category.name }}</td>
            <td style="font-weight: bold; text-decoration: underline;">{{ category.accNo }}</td>
            <td >{{ category.account }}</td>
            <td >{{ category.subAccount }}</td>
            <td >{{ category.detail }}</td>
            <td :style="{
        color: category.balance < 0 ? 'red' : null,
    
      }" class="text-right" style="font-weight: bold;" v-if="category.balance">${{ formatPrice(category.balance) }}</td>
            <td  v-else></td>

          </tr>

      
          <template v-for="(subItem, index) in category.subItems">

            
          <tr v-if="subItem.account">

          <td> &nbsp</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>

          </tr>

            <tr 
            
            :style="{
        borderBottom: subItem.account ? '1px solid black' : '1px dotted gray',
        borderCollapse: 'collapse'
    }"
    >
            <td></td>
            <td
            :style="{
        textDecoration: subItem.account ? 'underline' : 'none',
        fontWeight: subItem.account ? 'bold' : 'none',

    }"
          
            
            >{{ subItem.accNo }}</td>
            <td style="text-decoration: underline; font-weight: bold;">{{ subItem.account }}</td>
            <td>{{ subItem.subAccount }}</td>
            <td>{{ subItem.detail }}</td>
            <td class="text-right" v-if="subItem.balance!=0"
            
            :style="{
        color: subItem.balance < 0 ? 'red' : null,
    
      }"
    
            >${{  formatPrice( subItem.balance ?  subItem.balance : 0)  }}</td>
            <td class="text-right" v-else> ${{ formatPrice(0) }}</td>

          </tr>



          </template>
         
        </template>
      </tbody>
    </table>



        

        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import store from '@/store';
import accountingStoreModule from '@/views/accounting/accountingStoreModule.js';
import { onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import Vue from 'vue'; 



export default {
  components: { vSelect, BImg, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BOverlay, BFormGroup, flatPickr },

  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },
  

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },

    const periodOptions = [
      // { text: 'Website Status Selection', value: '' },
      { text: 'This Month', value: 'A' },
      { text: 'This Quarter', value: 'B' },
      { text: 'This Year', value: 'C' },
      { text: 'Last Month', value: 'D' }, // { text: "Last Month", value: "A" },
      { text: 'Last Quarter', value: 'E' }, // { text: "Last Year", value: "B" },
      { text: 'Last Year', value: 'F' }, // { text: "Last Year", value: "B" },
      { text: 'All Time', value: 'G' },
    ];

    const statusWebsiteOptions = [
      // { text: 'Website Status Selection', value: '' },
      { text: 'Published', value: 'yes' },
      { text: 'Unpublished', value: 'no' },
    ];

    const tempStatusOptions = [
      // { text: 'Inventory Status Selection', value: '' },
      { text: 'Created', value: 'A' },
      { text: 'Incoming - Consigment', value: 'B' },
      { text: 'In Stock - Consigment', value: 'C' },
      { text: 'Incoming', value: 'D' },
      { text: 'In Stock Pending', value: 'I' },
      { text: 'In Stock - Pending Receiving', value: 'E' },
      { text: 'In Stock - Pending Payment', value: 'F' },
      { text: 'In Stock', value: 'G' },
      { text: 'Created', value: 'P' },

      { text: 'Sold Pending', value: 'PA' },
      { text: 'Delivered - Pending Payment', value: 'PB' },
      { text: 'Sold', value: 'PC' },
      { text: 'Delivered', value: 'PD' },
      { text: 'Closed', value: 'PE' },

      { text: 'In Stock Pending - Sold Order', value: 'PF' },
      { text: 'In Stock Pending - Sold Order', value: 'PL' },

      { text: 'Incoming - Sold Order', value: 'PG' },
      { text: 'Consigment - Sold Order', value: 'PH' },
      { text: 'Sold Order', value: 'PI' },
      { text: 'Funded - Pending Delivery', value: 'PK' },
    ];

    const tempQuoteStatusOptions = [
      { label: 'Draft', value: 'A' },
      { label: 'Pending Manager', value: 'B' },
      { label: 'Approved', value: 'C' },
      { label: 'Not Approved', value: 'D' },
      { label: 'Sent To Client', value: 'E' },
      { label: 'Win', value: 'F' },
      { label: 'Loss', value: 'P' },
      { label: 'Canceled Sale', value: 'J' },
      { label: 'Win-Closed', value: 'H' },
    ];

    return {
      startDate: null,
      filter: null,
      filterOn: ['id', 'titleValue', 'status', 'listingPrice', 'publish'],
      quoteFields: [
        { key: 'orderId', label: 'ORDER ID', class: 'text-center', thStyle: 'width: 5%' },
        { key: 'type', label: 'ORDER TYPE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'title', label: 'ITEM TITLE', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'description', label: 'DESCRIPTION', class: 'text-center', thStyle: 'width: 30%' },
        { key: 'date', label: 'DATE', class: 'text-center', thStyle: 'width: 10%' },
        { key: 'price', label: 'TOTAL', class: 'text-center', thStyle: 'width: 15%' },
      ],

      config: {
        dateFormat: 'd/m/Y',
        mode: 'range',
      },

      categoriez : [],


      filterDateStart: null,
      filterDateEnd: null,
      rangeDate: null,

      perPage: localStorage.getItem('inventoryShow') ?? 8,
      fetchInventorys: [],
      listInventorys: [],
      currentPage: 1,
      perPageOptions: [5, 8, 25, 50, 100],
      tempQuoteStatusOptions,
      totalRows: 0,
      baseURL: store.state.app.baseURL,
      loading: false,
      statusWebsiteOptions,
      tempStatusOptions,
      statusOptions: [],
      statusWebsite: null,
      status: null,
      inHeaders: [],
      colWidthFactor: null,
      periodOptions,
      selectedPeriod: null,
      totalProfit: null,
      totalIncome: null,
      totalIncomeSales: null,
      totalIncomeCategories: null,
      totalPurchase: null,
      totalCogs: null,
      totalOp: null,
      adjustment: null,
      totalCostOfSales: null,
      netEarnings: null,
      grossProfit: null,
      packTotal: null,

      categories: [],
      categoriesIncome: [],
      categoriesCogs: [],
      categoriesOperational: [],

      month: null,
      monthStart: null,
      monthEnd: null,
      quarter: null,
      quarterStart: null,
      quarterEnd: null,
      financialYearStart: null,
      financialYearEnd: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem('inventoryShow', val);
      },
    },

    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];

            this.getItems();
          }
        }
      },
    },
  },

  methods: {


    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.getItems();
      }
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    generateReport() {
      this.download = true;
      store.dispatch('accounting/compIncomeDownload', { start: this.filterDateStart, end: this.filterDateEnd }).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Comprehensive Income.pdf');
          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Report is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
        }
      });
    },
    onRowSelected(item) {
      item.toggleDetails();
    },

    onRowPurchaseSelected(item) {
      if (this.$Can('purchase_show')) {
        router.push({ name: 'quote-preview', params: { id: item[0].id } });
      }
    },

    onRowSaleSelected(item) {
      if (this.$Can('sale_show')) {
        router.push({ name: 'sales-preview', params: { id: item[0].id } });
      }
    },
    onError(event) {
      event.target.src = '../no-car.png';
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    getItems() {
      this.loading = true;
      store
        .dispatch('accounting/businessOverviewInventory', { start: this.filterDateStart, end: this.filterDateEnd })
        .then((res) => {

          this.filterDateStart = res.data.startDate;
          this.filterDateEnd = res.data.endDate;
          this.categoriez = res.data.balanceRows;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  created() {
    this.selectedPeriod = 'B';
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.toCapitalFirst {
  text-transform: capitalize;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
